/* eslint-disable array-callback-return */
import React from 'react';
import { Link } from 'react-router-dom';

function PreviewOrder({ selectedOption }) {
  // const navigate = useNavigate();
  const formValues = {
    email: selectedOption.email,
    rankProductId: selectedOption.rankProductId,
  };

  if (selectedOption) {
    console.log(selectedOption);
  }

  if (!selectedOption) {
    return (
      <div>
        <p>No items are selected. Please go back and select one.</p>
        <h1>Previous Page with Form</h1>
        {/* Link to the form page with formValues */}
        <Link to="/signup" state={formValues}>
          Go to Form Page
        </Link>
      </div>
    );
  }

  return (
    <div>
      <h1>Preview order</h1>
      <ul>
        {Object.keys(selectedOption).map((key) => (
          <li key={key}>
            <b>
              {key}
              :
            </b>
            {' '}
            {selectedOption[key]}
          </li>
        ))}
      </ul>
      {/* Link to the form page with formValues */}
      <Link to="/signup" state={formValues}>Go to Form Page</Link>
    </div>
  );
}

export default PreviewOrder;
