import React from 'react';
import RankCategory from './RankCategory';
// import RankForm from './RankForm';

function RankPage() {
  return (
    <>
      <h3>Rank Page</h3>
      <RankCategory />
    </>
  );
}

export default RankPage;
