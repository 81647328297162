import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from 'react-router-dom';
import LogoutPage from '../page/logout/LogoutPage';
import DashboardPage from '../page/dashboard/DashboardPage';
import ComparePage from '../page/compare/ComparePage';
import SearchPage from '../page/search/SearchPage';
import SchoolPage from '../page/school/SchoolPage';
import RankingPage from '../page/ranking/RankingPage';
import CompareResultPage from '../page/compare-result/CompareResultPage';
import SearchResultPage from '../page/search-result/SearchResultPage';
import ErrorPage from '../page/error/ErrorPage';

function AuthenticatedRoute() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/search">Search</Link>
            </li>
            <li>
              <Link to="/compare">Compare</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route exact path="/logout" element={<LogoutPage />} />
          <Route index path="/dashboard" element={<DashboardPage />} />
          <Route exact path="/search" element={<SearchPage />} />
          <Route exact path="/compare" element={<ComparePage />} />
          <Route exact path="/schools/:display_name/:sp_uuid" element={<SchoolPage />} />
          <Route exact path="/products/:product_name/:rp_uuid" element={<RankingPage />} />
          <Route exact path="/search-result" element={<SearchResultPage />} />
          <Route exact path="/compare-result" element={<CompareResultPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default AuthenticatedRoute;
