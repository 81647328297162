import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import adminService from '../../../service/admin';
import productService from '../../../service/product';
import ErrorPage from '../../error/ErrorPage';
import { UserContext, AdminContext } from '../../../context';

function RankCategory() {
  const navigate = useNavigate();
  const {
    register, handleSubmit, setValue, setError, clearErrors,
  } = useForm();
  const { token } = useContext(UserContext);
  const { currentProfileYear } = useContext(AdminContext);
  const [rankCategory, setRankCategory] = useState([]);
  const [rankProducts, setRankProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleError = (message) => {
    setErrorMessage(message);
    setError('rankCategoryId', { type: 'manual', message });
    setError('rankProductId', { type: 'manual', message });
    setTimeout(() => {
      setErrorMessage(null);
      clearErrors('rankCategoryId');
      clearErrors('rankProductId');
    }, 3000);
  };

  useEffect(() => {
    const fetchRankCategory = async () => {
      try {
        const data = await adminService.fetchRankCategory(token);
        setRankCategory(data);
      } catch (error) {
        handleError(error.message);
      }
    };

    fetchRankCategory();
  }, [token]);

  useEffect(() => {
    const fetchRankProducts = async () => {
      try {
        const products = await productService.fetchBasicRankProductInfo(currentProfileYear);
        setRankProducts(products);
      } catch (error) {
        handleError(error.message);
      }
    };

    fetchRankProducts();
  }, [currentProfileYear]);

  const handleRankProductUpdate = async (rankProductId) => {
    navigate(`/admin/profile-years/${Number(currentProfileYear)}/rank-products/${Number(rankProductId)}/update`);
  };

  const handleRankProductCreate = async (rankCategoryId) => {
    const rankProductId = rankCategoryId + (currentProfileYear % 100);
    const category = rankCategory.find(
      (item) => item.rank_category_id === Number(rankCategoryId),
    );
    const { name, country, rank_max } = category;
    const active = 1;

    const payload = {
      rankProductId,
      rankCategoryId,
      name: `${name} ${currentProfileYear}`,
      description: `${name} ${currentProfileYear}`,
      currentProfileYear,
      active,
      country,
      rank_max,
    };

    try {
      const result = await adminService.addRankProduct(payload, token);

      if (result.affectedRows > 0 && !errorMessage) {
        alert('navigating... to rank-form...');
        navigate(`/admin/profile-years/${Number(currentProfileYear)}/rank-products/${Number(rankProductId)}/create`);
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleRankCategoryChange = (event) => {
    setValue('rankProductId', ''); // Reset rankProductId to empty string when rankCategoryId changes
    return event;
  };

  const handleRankProductChange = (event) => {
    setValue('rankCategoryId', ''); // Reset rankCategoryId to empty string when rankProductId changes
    return event;
  };

  const onSubmit = async (data) => {
    const { rankCategoryId, rankProductId } = data;

    if (rankProductId.length > 0) {
      return handleRankProductUpdate(rankProductId);
    }
    return handleRankProductCreate(rankCategoryId);
  };

  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <>
      <h3>Select Ranking</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Select existing ranking:</label>
        <select
          {...register('rankProductId')}
          onChange={handleRankProductChange}
        >
          <option value="">--Please choose an option---</option>
          {rankProducts.length > 0
            && rankProducts.map((product) => (
              <option key={product.rank_product_id} value={product.rank_product_id}>
                {product.display_name}
              </option>
            ))}
        </select>
        <hr />
        -- OR --
        <hr />
        <label>Create a new ranking:</label>
        <select
          {...register('rankCategoryId')}
          onChange={handleRankCategoryChange}
        >
          <option value="">--Please choose an option---</option>
          {rankCategory.length > 0
            && rankCategory.map((category) => (
              <option key={category.rank_category_id} value={category.rank_category_id}>
                {category.name}
              </option>
            ))}
        </select>
        <hr />
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </>
  );
}

export default RankCategory;
