import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import userService from '../../service/user';
import orderService from '../../service/order';
import productService from '../../service/product';
import Loading from '../../component/Loading';
import Signup from './Signup';
import ErrorPage from '../error/ErrorPage';

function SignupPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [rankProducts, setRankProducts] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState(null);
  const [formValues, setFormValues] = useState({});

  if (Object.keys(formValues).length !== 0) {
    console.log(formValues);
  }

  const convertPlansToObject = (arrayOfObjects) => {
    const plansObject = {};
    for (const plan of arrayOfObjects) {
      plansObject[plan.identifier] = plan; // Use identifier as key, whole plan object as value
    }
    return plansObject;
  };

  useEffect(() => {
    // Check if state exists in location
    if (location.state) {
      setFormValues(location.state);
    }
  }, [location]);

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const plans = await orderService.fetchSubscriptionPlans();
        const [profileYear] = plans.map((plan) => plan.profile_year);
        const products = await productService.fetchBasicRankProductInfo(profileYear);

        setSubscriptionPlans(convertPlansToObject(plans));
        setRankProducts(products);
      } catch (error) {
        setErrorMessage(error.message);

        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    };

    dataFetch();
  }, []);

  const handleSignupSubmit = async (data) => {
    setTimeout(() => {
      navigate('/checkout', { state: { selectedOption: data } });
    }, 1);

    return false;

    // eslint-disable-next-line no-unreachable
    const items = [];

    try {
      // only after credit card is successful

      const result = await userService.addUser(data);
      const { insertId: user_id } = result;
      const role_id = 3; // remove hard coded role id

      const role = await userService.addUserRole(user_id, role_id);
      const product_id = data.selectedProduct;
      const duration = (product_id === 25) ? 12 : 3;

      const order = await orderService.addOrder(user_id, product_id, duration);

      items.push(result, order, role);
      return items;
    } catch (error) {
      setErrorMessage('Sorry, something went wrong with signup....');

      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }

    // return false;
  };

  if (rankProducts == null) return <Loading />;
  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <Signup
      onSubmit={handleSignupSubmit}
      rankProducts={rankProducts}
      subscriptionPlans={subscriptionPlans}
      formValues={formValues}
    />
  );
}

export default SignupPage;
