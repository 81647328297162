import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import Logout from './Logout';

function LogoutPage() {
  const {
    setIsAuthenticated,
    setToken,
    isAuthenticated,
  } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    setIsAuthenticated(false);
    setToken(null);

    if (!isAuthenticated) {
      setTimeout(() => {
        navigate('/login');
      }, 500);
    }
  });
  return (
    <Logout />
  );
}

export default LogoutPage;
