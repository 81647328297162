import { createContext } from 'react';

const UserContext = createContext({
  isAuthenticated: false,
  token: null,
  userInfo: null,
  userSubscription: null,
  setIsAuthenticated: () => {},
  setToken: () => {},
  setUserInfo: () => {},
  setUserSubscription: () => {},
});

export default UserContext;
