import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import usState from '../../util/formHelper/usState.json';
import caProvince from '../../util/formHelper/caProvince.json';
import ukCounty from '../../util/formHelper/ukCounty.json';
import distance from '../../util/formHelper/distance.json';
import schoolType from '../../util/formHelper/schoolType.json';
import enrollmentGender from '../../util/formHelper/enrollmentGender.json';

function Search({ onSubmit }) {
  const [usStateData] = useState(usState);
  const [caProvinceData] = useState(caProvince);
  const [ukCountyData] = useState(ukCounty);
  const [distanceData] = useState(distance);
  const [schoolTypeData] = useState(schoolType);
  const [enrollmentGenderData] = useState(enrollmentGender);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      distance: 30,
      profile_year: '2019',
    },
  });

  const watchCountry = watch('country');

  const onFormSubmit = async (data, event) => {
    event.preventDefault();

    const removedSpace = data.postal_code.replace(/ /g, '');
    // eslint-disable-next-line no-param-reassign
    data.postal_code = removedSpace;
    onSubmit(data);
  };

  const usStateOption = () => usStateData.map(
    (state) => <option value={state.id} key={state.id}>{state.name}</option>,
  ).sort((a, b) => b.name - a.name);

  const caProvinceOption = () => caProvinceData.map(
    (state) => <option value={state.id} key={state.id}>{state.name}</option>,
  ).sort((a, b) => b.name - a.name);

  const ukCountyOption = () => ukCountyData.map(
    (state) => <option value={state.name} key={state.id}>{state.name}</option>,
  ).sort((a, b) => b.name - a.name);

  return (
    <>
      <h1>Search</h1>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <h3>Profile Year</h3>
        <label>
          <div>
            <input
              type="radio"
              value="2019"
              {...register('profile_year')}
            />
            2019
          </div>
        </label>
        <label>
          <div>
            <input
              type="radio"
              value="2017"
              {...register('profile_year')}
            />
            2017
          </div>
        </label>
        <h3>Country (*required)</h3>
        <select {...register('country', { required: true })}>
          <option value="">Please select</option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="UK">England</option>
        </select>
        {errors.country && <span>This field is required</span>}
        <h3>School Type</h3>
        <select {...register('school_type', { required: false })}>
          <option value="">No preference</option>
          {(schoolTypeData) && schoolTypeData.map(
            (type) => <option value={type.id} key={type.id}>{type.name}</option>,
          )}
        </select>
        <h3>Enrollment Gender</h3>
        <select {...register('enrollment_gender', { required: false })}>
          <option value="">No preference</option>
          {(enrollmentGenderData) && enrollmentGenderData.map(
            (gender) => <option value={gender.id} key={gender.id}>{gender.name}</option>,
          )}
        </select>
        <h3>State, County or Province</h3>
        {(watchCountry === 'US')
           && (
           <select {...register('state', { required: false })}>
             <option value="">Please select</option>
             { usStateOption() }
           </select>
           )}
        {(watchCountry === 'CA') && (
        <select {...register('province', { required: false })}>
          <option value="">Please select</option>
          { caProvinceOption() }
        </select>
        )}
        {(watchCountry === 'UK') && (
        <select {...register('county', { required: false })}>
          <option value="">Please select</option>
          { ukCountyOption() }
        </select>
        )}
        <h3>Distance</h3>
        <select {...register('distance', { required: false })}>
          {(distanceData) && distanceData.map(
            (dist) => <option value={dist.id} key={dist.id}>{dist.name}</option>,
          )}
        </select>

        <h4>Postal code</h4>
        <input {...register('postal_code', { required: false })} />
        <h4>Submit search</h4>
        <input type="submit" />
      </form>
    </>
  );
}

export default Search;
