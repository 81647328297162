import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import ErrorPage from '../error/ErrorPage';

function Payment() {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    }

    setIsProcessing(false);
  };

  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;
  return (
    <>
      <h3>Payment</h3>
      <form id="payment-form" onSubmit={handlePaymentSubmit}>
        <PaymentElement />
        <button type="submit" disabled={isProcessing} id="submit">
          <span id="button-text">
            {isProcessing ? 'Processing...' : 'Pay now'}
          </span>
        </button>
      </form>
    </>
  );
}

export default Payment;
