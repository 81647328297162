import React, { useState, useEffect, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  UserContext,
  AdminContext,
} from './context';
import {
  AdminRoute,
  SchoolAdminRoute,
  AuthenticatedRoute,
  UnauthenticatedRoute,
} from './route';

const theme = createTheme({});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userSubscription, setUserSubscription] = useState(null);
  const [currentProfileYear, setCurrentProfileYear] = useState(null);
  const [availableProfileYears, setAvailableProfileYears] = useState(null);

  useEffect(() => { // trigger logout
    const logoutTimer = setTimeout(() => {
      setIsAuthenticated(false);
    }, 1000 * 60 * 60); // after 1 hour

    return () => clearTimeout(logoutTimer);
  }, []);

  const userValue = useMemo(() => ({
    isAuthenticated,
    token,
    userInfo,
    userSubscription,
    setToken,
    setIsAuthenticated,
    setUserInfo,
    setUserSubscription,
  }), [isAuthenticated, token, userInfo, userSubscription]);

  const adminValue = useMemo(() => ({
    currentProfileYear,
    availableProfileYears,
    setCurrentProfileYear,
    setAvailableProfileYears,
  }), [currentProfileYear, availableProfileYears]);

  const routeComponent = useMemo(() => {
    if (isAuthenticated) {
      switch (userInfo?.role) {
        case 'customer':
          return <AuthenticatedRoute />;
        case 'admin':
          return (
            <AdminContext.Provider value={adminValue}>
              <AdminRoute />
            </AdminContext.Provider>
          );
        case 'school_admin':
          return <SchoolAdminRoute />;
        default:
          return <UnauthenticatedRoute />;
      }
    } else {
      return <UnauthenticatedRoute />;
    }
  }, [isAuthenticated, userInfo, adminValue]);

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={userValue}>
        {routeComponent}
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;

/*
  Note: The useMemo hook is used to memoize the userValue, adminValue,
  and routeComponent values. This can potentially improve performance
  by preventing unnecessary recalculations when the dependencies remain
  unchanged.
*/
