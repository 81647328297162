import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
// import ErrorPage from '../error/ErrorPage';

function Error({ errorMessage }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Alert severity="error">{errorMessage}</Alert>
    </Box>
  );
}

export default Error;
