import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import adminService from '../../service/admin';
import ErrorPage from '../error/ErrorPage';
import { UserContext, AdminContext } from '../../context';

function ProfileYear() {
  const { register, handleSubmit } = useForm();
  const { token } = useContext(UserContext);
  const { availableProfileYears } = useContext(AdminContext);
  const [sourceProfileYears, setSourceProfileYears] = useState([]);
  const [targetProfileYears, setTargetProfileYears] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (availableProfileYears !== null) {
      setSourceProfileYears(availableProfileYears);
    }
  }, [availableProfileYears]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const futureYears = [];

    for (let i = 0; i <= 3; i++) {
      futureYears.push(currentYear + i);
    }

    setTargetProfileYears(futureYears);
  }, []);

  const onSubmit = async (data) => {
    try {
      const result = await adminService.copySchoolProfiles(data, token);
      alert(`${result.affectedRows} rows inserted with profile year ${data.targetProfileYear}`);

      // if (result.affectedRows && result.affectedRows.length > 1) {
      //   setAdminStore({
      //     profileYear: data.targetProfileYear,
      //   });
      // }
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <>
      <h3>Copy school profiles using updated profile year:</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Select Source Year:</label>
        <select {...register('sourceProfileYear')}>
          <option>Select source year</option>
          {sourceProfileYears.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <label>Select Target Year:</label>
        <select {...register('targetProfileYear')}>
          <option>Select target year</option>
          {targetProfileYears.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </>
  );
}

export default ProfileYear;
