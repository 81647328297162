import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import LoginPage from '../page/login/LoginPage';
import LogoutPage from '../page/logout/LogoutPage';
import SignupPage from '../page/signup/SignupPage';
import CheckoutPage from '../page/checkout/CheckoutPage';

function SchoolAdminRoute() {
  return (
    <Router>
      <Routes>
        <Route exact path="/signup" element={<SignupPage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/logout" element={<LogoutPage />} />
        <Route exact path="/checkout" element={<CheckoutPage />} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default SchoolAdminRoute;
