import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import productService from '../../service/product';
import Ranking from './Ranking';
import Loading from '../../component/Loading';
import ErrorPage from '../error/ErrorPage';
import UserContext from '../../context/UserContext';

function RankingPage() {
  const location = useLocation();
  const { token } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rankingData, setRankingData] = useState(null);
  const { rank_product_id: rankProductId, profile_year: profileYear } = location.state;

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const data = await productService.fetchRankProduct(rankProductId, profileYear, token);
        console.log(data);

        setRankingData(data);
      } catch (error) {
        setErrorMessage(error.message);
        console.log(errorMessage);

        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    };

    dataFetch();
  }, []);

  // data is object type
  if (rankingData == null) return <Loading />;
  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <Ranking rankingData={rankingData} />
  );
}

export default RankingPage;
