import { api } from '../axios/config';
import { handleApiError } from '../util/errorHelper';

const baseUrl = '/api/products';

const fetchRankProduct = async (rankProductId, profileYear, token) => {
  try {
    const response = await api.get(`${baseUrl}/${rankProductId}/profile-years/${profileYear}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const fetchBasicRankProductInfo = async (profileYear) => {
  try {
    const response = await api.get(`${baseUrl}/profile-years/${profileYear}/basic`);
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default {
  fetchRankProduct,
  fetchBasicRankProductInfo,
};
