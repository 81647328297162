import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { map } from 'lodash';
import schoolService from '../../service/school';
import Compare from './Compare';
import Loading from '../../component/Loading';
import ErrorPage from '../error/ErrorPage';
import UserContext from '../../context/UserContext';

function ComparePage() {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [compareOptionData, setCompareOptionData] = useState([]);
  const [profileYear, setProfileYear] = useState(null);

  const handleCompareSubmit = (selectedOptionData) => {
    const schoolIdArray = map(selectedOptionData, 'school_id');
    navigate('/compare-result', { state: { schoolIds: schoolIdArray, profileYear } });
  };

  const {
    register,
    watch,
  } = useForm({
    defaultValues: {
      profile_year: '2019', // this needs updating
    },
  });

  const dataFetch = async (year) => {
    setProfileYear(year);

    try {
      // filter school by purchase option...
      const data = await schoolService.fetchBasicSchoolInfo(year, token);
      const optionData = data.map((obj) => {
        const item = {};
        item.school_id = obj.school_id;
        item.name = `${obj.display_name} - ${obj.city}, ${obj.country} | ${obj.profile_year}`;
        item.country = obj.country;
        item.profile_year = obj.profile_year;
        item.uuid = obj.uuid;
        return item;
      });
      setCompareOptionData(optionData);
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  useEffect(() => {
    dataFetch('2019'); // default <= this needs update...
  }, []);

  if (compareOptionData == null) return <Loading />;
  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <>
      <form>
        <h3>Profile Year</h3>
        <label>
          <div>
            <input
              type="radio"
              value="2019"
              {...register('profile_year', {
                onChange: (e) => dataFetch(e.target.value),
              })}
            />
            2019
          </div>
        </label>
        <label>
          <div>
            <input
              type="radio"
              value="2017"
              {...register('profile_year', {
                onChange: (e) => dataFetch(e.target.value),
              })}
            />
            2017
          </div>
        </label>
        <pre>{JSON.stringify(watch(), null, 2)}</pre>
      </form>
      <Compare compareOptionData={compareOptionData} onSubmit={handleCompareSubmit} />
    </>
  );
}

export default ComparePage;
