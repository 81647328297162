import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginService from '../../service/login';
import Login from './Login';
import ErrorPage from '../error/ErrorPage';
import UserContext from '../../context/UserContext';

function LoginPage() {
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    setIsAuthenticated,
    setToken,
    setUserInfo,
  } = useContext(UserContext);

  const navigate = useNavigate();

  const handleLoginSubmit = async (formData) => {
    const email = formData.get('email');
    const password = formData.get('password');

    try {
      const result = await loginService.login({
        email, password,
      });

      if (!result) {
        setErrorMessage('Error...');
      }
      const {
        authenticated, token, user_id, role,
      } = result[0];

      /* fetch all user order */
      // const bearer = `Bearer ${token}`;
      // const order = await customerService.findById(customerId, token);

      setIsAuthenticated(authenticated);

      if (authenticated) {
        setToken(token);
        setUserInfo({
          email,
          user_id,
          authenticated,
          token,
          role,
        });

        switch (role) {
          case 'admin':
            navigate('/admin');
            break;
          case 'school_admin':
            navigate('/school-admin');
            break;
          default:
            navigate('/dashboard');
        }
      }
    } catch (error) {
      setErrorMessage('Error...');

      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <Login onSubmit={handleLoginSubmit} />
  );
}

export default LoginPage;
