import axios from 'axios';

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API;

const api = axios.create({
  url: 'v1',
  baseURL: apiUrl,
  timeout: 8000,
  headers: {
    Accept: 'application/json',
  },
});

export {
  api,
  apiUrl,
};
