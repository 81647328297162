import React, { useContext, useState, useEffect } from 'react';
import userService from '../../service/user';
import UserContext from '../../context/UserContext';
import Dashboard from './Dashboard';
import Loading from '../../component/Loading';
import ErrorPage from '../error/ErrorPage';

function DashboardPage() {
  const { userInfo, userSubscription, setUserSubscription } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchUserSubscription = async () => {
      try {
        const { user_id: userId, token } = userInfo;
        const data = await userService.fetchUserSubscription(userId, token);
        setUserSubscription(data);
      } catch (error) {
        setErrorMessage(error.message);
        console.log(errorMessage);

        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    };

    fetchUserSubscription();
  }, [userInfo, setUserSubscription, errorMessage]);

  if (userSubscription === null) return <Loading />;
  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <>
      <h1>Dashboard Page</h1>
      <Dashboard />
    </>
  );
}

export default DashboardPage;
