import Joi from 'joi';

const signupSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).required(),
  password: Joi.string().min(5).alphanum().required(),
  confirmPassword: Joi.string().min(5).alphanum().required(),
  subscriptionPlanId: Joi.number().integer().allow(null), // this is pulled from state object cannot be required
  subscriptionPlanIdentifier: Joi.string().allow(''), // this is pulled from state object cannot be required
  rankProductId: Joi.when('subscriptionPlanIdentifier', {
    is: 'rank_product_one',
    then: Joi.number().integer().min(1).required(),
    otherwise: Joi.string().allow(''),
  }),
});

export default signupSchema;
