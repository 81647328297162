import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import orderService from '../../service/order';
import Payment from './Payment';
import PreviewOrder from './PreviewOrder';
import ErrorPage from '../error/ErrorPage';

function CheckoutPage() {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const { selectedOption } = location.state;

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const { publishable_key } = await orderService.fetchStripeConfig();
        setStripePromise(loadStripe(publishable_key));
      } catch (error) {
        setErrorMessage('Sorry, something went wrong...');

        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    };

    dataFetch();
  }, []);

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const { client_secret } = await orderService.createPaymentIntent({
          amount: 199,
          currency: 'usd',
        });

        setClientSecret(client_secret);
      } catch (error) {
        setErrorMessage('Sorry, something went wrong...');

        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    };

    dataFetch();
  }, []);

  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <>
      <h1>Checkout</h1>
      <PreviewOrder selectedOption={selectedOption} />
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <Payment />
        </Elements>
      )}
    </>
  );
}

export default CheckoutPage;

// https://docs.stripe.com/stripe-js/react#elements-props-stripe
