import { createContext } from 'react';

const AdminContext = createContext({
  currentProfileYear: null,
  availableProfileYears: null,
  setCurrentProfileYear: () => {},
  setAvailableProfileYears: () => {},
});

export default AdminContext;
