import { api } from '../axios/config';
import { handleApiError } from '../util/errorHelper';

const baseUrl = '/api/search';

const findSchool = async (criteria) => {
  try {
    const {
      country,
      postal_code,
      state,
      province,
      county,
      school_type,
      enrollment_gender,
      distance,
      profile_year,
    } = criteria.data;

    const { token } = criteria;
    const response = await api.post(baseUrl, {
      country,
      postal_code,
      state,
      province,
      county,
      school_type,
      enrollment_gender,
      distance,
      profile_year,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default { findSchool };
