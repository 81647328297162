import React, { useState, useEffect, useContext } from 'react';
import { AdminContext } from '../../../context';
import ProfileYearDropDown from '../ProfileYearDropDown';

function AdminHeader() {
  const { currentProfileYear } = useContext(AdminContext);
  const [profileYear, setProfileYear] = useState([]);

  useEffect(() => {
    if (currentProfileYear !== null) {
      setProfileYear(currentProfileYear);
    }
  }, [currentProfileYear]);

  return (
    <>
      <h1>Admin Header</h1>
      <div>
        Current profile year:
        { profileYear }
      </div>
      <div>
        <ProfileYearDropDown />
      </div>
      <hr />
    </>
  );
}

export default AdminHeader;
