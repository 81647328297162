import React from 'react';
import { useLocation } from 'react-router-dom';
import SearchResult from './SearchResult';

function SearchResultPage() {
  const location = useLocation();
  const { searchResult } = location.state;

  return (
    <SearchResult searchResult={searchResult} />
  );
}

export default SearchResultPage;
