/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';
import { merge } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import signupSchema from '../../util/schema/signupSchema';

function Signup({
  onSubmit, rankProducts, subscriptionPlans, formValues,
}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // console.log(subscriptionPlans);
  // console.log(rankProducts);

  console.log(formValues);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    resolver: joiResolver(signupSchema),
  });

  useEffect(() => {
    reset(); // clear form after successful registration
  }, [isSuccess]);

  useEffect(() => {
    // Check if state exists in location
    if (Object.keys(formValues).length) {
      setValue('email', formValues.email);
      setValue('rankProductId', formValues.rankProductId);
    }
  }, []);

  /*
   * In React, when you click a button with an onClick handler,
   * it will execute the handler function before the form's submit event occurs.
   * This means that the state will be updated when the button is clicked,
   * and then the form will be submitted.
   */

  const onFormSubmit = async (data) => {
    if (selectedOption == null) {
      return; // just in case...
    }

    const selected = merge({
      subscriptionPlanId: selectedOption.subscription_plan_id,
      subscriptionPlanIdentifier: selectedOption.identifier,
    }, data);
    const result = await onSubmit(selected);

    if (result.length === 3) { // refactor...
      setIsSuccess(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <h1>Signup</h1>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          {...register('email', { required: 'Email is required' })}
        />
        {errors.email && <span className="error">{errors.email.message}</span>}
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          {...register('password', { required: 'Password is required', minLength: 5 })}
        />
        {errors.password && (
        <span className="error">
          {errors.password.message}
          {' '}
          (Minimum length: 8 characters)
        </span>
        )}
      </div>
      <div>
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          {...register(
            'confirmPassword',
            {
              required: 'Confirm password is required',
              validate: (value) => {
                if (watch('password') !== value) {
                  return 'Password does not match. Please try again.';
                }
                return null;
              },
            },
          )}
        />
        {errors.confirmPassword && <span className="error">{errors.confirmPassword.message}</span>}
      </div>
      <div>
        <h3>Option A</h3>
        <Controller
          name="rankProductId"
          control={control}
          defaultValue="0"
          render={({ field }) => (
            <select {...field}>
              <option value="">Please select a ranking to purchase</option>
              {rankProducts.map((option) => (
                <option key={option.rank_product_id} value={option.rank_product_id}>
                  {option.display_name}
                </option>
              ))}
            </select>
          )}
        />

        {errors.selectedProduct && <span className="error">{errors.selectedProduct.message}</span>}
        {(!subscriptionPlans || Object.keys(subscriptionPlans).length !== 0)
          ? (
            <div>
              <button
                type="submit"
                onClick={() => setSelectedOption({
                  subscription_plan_id: subscriptionPlans.rank_product_one.subscription_plan_id,
                  identifier: subscriptionPlans.rank_product_one.identifier,
                })}
              >
                Select Option A
              </button>
            </div>
          )
          : null }
        {isSuccess && <p className="success">Signup successful!</p>}
      </div>

      <div>
        <h3>Option B</h3>
        {(!subscriptionPlans || Object.keys(subscriptionPlans).length !== 0)
          ? (
            <div>
              <button
                onClick={() => setSelectedOption({
                  subscription_plan_id: subscriptionPlans.rank_product_all.subscription_plan_id,
                  identifier: subscriptionPlans.rank_product_all.identifier,
                })}
                type="submit"
              >
                Select Option B
              </button>
            </div>
          )
          : null }
        {errors.selectedProduct && <span className="error">{errors.selectedProduct.message}</span>}
        {isSuccess && <p className="success">Signup successful!</p>}
      </div>
    </form>
  );
}

export default Signup;
