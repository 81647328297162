import { api } from '../axios/config';
import { handleApiError } from '../util/errorHelper';

const baseUrl = '/api/login';

const login = async (credentials) => {
  try {
    const response = await api.post(baseUrl, credentials);
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default { login };
