import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import searchService from '../../service/search';
import UserContext from '../../context/UserContext';
import Search from './Search';
import ErrorPage from '../error/ErrorPage';

function SearchPage() {
  const [errorMessage, setErrorMessage] = useState(null);
  const { token } = useContext(UserContext);

  const navigate = useNavigate();
  const handleSearchSubmit = async (data) => {
    try {
      const result = await searchService.findSchool({
        data, token,
      });

      if (result.length) {
        navigate('/search-result', { state: { searchResult: [...result] } });
      } else {
        // use constants for error message...
        setErrorMessage('No results found. Please expand the search criteria...');

        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    } catch (error) {
      setErrorMessage('Error: search error exception...');

      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <Search onSubmit={handleSearchSubmit} />
  );
}

export default SearchPage;
