import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from 'react-router-dom';

import LogoutPage from '../page/logout/LogoutPage';
import Layout from '../page/admin/Layout';
import AdminHome from '../page/admin/AdminHome';
import AdminHeader from '../page/admin/shared/Header';
import AdminFooter from '../page/admin/shared/Footer';
import ProfileYear from '../page/admin/ProfileYear';
import RankPage from '../page/admin/rank/RankPage';
import RankForm from '../page/admin/rank/RankForm';
import ErrorPage from '../page/error/ErrorPage';

function AdminRoute() {
  return (
    <Router>
      <section>
        <nav>
          <ul>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
            <li>
              <Link to="/admin">Admin</Link>
            </li>
            <li>
              <Link to="/layout">Layout</Link>
            </li>
          </ul>
        </nav>
        <AdminHeader />
        <Routes>
          <Route exact path="/logout" element={<LogoutPage />} />
          <Route exact path="/admin" element={<AdminHome />} />
          <Route exact path="/admin/profile-years" element={<ProfileYear />} />
          <Route exact path="/admin/profile-years/:profile_year/rank-products" element={<RankPage />} />
          <Route path="/admin/profile-years/:profile_year/rank-products/:rank_product_id/create" element={<RankForm />} />
          <Route path="/admin/profile-years/:profile_year/rank-products/:rank_product_id/update" element={<RankForm />} />
          <Route exact path="/layout" element={<Layout />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <AdminFooter />
      </section>
    </Router>
  );
}

export default AdminRoute;
