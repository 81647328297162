import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import Loading from '../../component/Loading';
import UserContext from '../../context/UserContext';

function Dashboard() {
  const { userInfo, userSubscription } = useContext(UserContext);
  if (userSubscription == null) return <Loading />;

  return (
    <>
      <h1>Dashboard</h1>
      <h3>
        { `Hello, ${userInfo.email}` }
      </h3>
      <ul>
        { (userSubscription && userSubscription != null)
          ? userSubscription.products.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${item.rank_product_id}-${item.uuid}-${index}`}>
              <Link to={`/products/${kebabCase(item.product_name)}/${item.uuid}`} state={item}>
                {item.rank_product_id}
                :
                {' '}
                {item.product_name}
              </Link>
            </li>
          )) : null }
      </ul>
      <div style={{ height: '500px', border: '1px solid red', overflowY: 'auto' }}>
        <ol>
          { (userSubscription && userSubscription != null)
            ? Object.keys(userSubscription.schools).map((key) => (
              <div key={key}>
                <h2>{key}</h2>
                <ul>
                  {userSubscription.schools[key].map((item) => (
                    <li key={`${item.sp_uuid}`}>
                      <Link to={`/schools/${kebabCase(item.display_name)}/${item.sp_uuid}`} state={item}>
                        {item.display_name}
                        :
                        {' '}
                        {item.review_count}
                        {' - '}
                        {item.profile_year}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )) : null }
        </ol>
      </div>
    </>

  );
}

export default Dashboard;
