/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import schoolService from '../../service/school';
import CompareResult from './CompareResult';
import UserContext from '../../context/UserContext';
import Loading from '../../component/Loading';
import ErrorPage from '../error/ErrorPage';

function CompareResultPage() {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [schoolData, setSchoolData] = useState([]);
  const { token } = useContext(UserContext);
  const { schoolIds, profileYear } = location.state;

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const data = await schoolService.fetchSchools(schoolIds, profileYear, token);
        setSchoolData(data);
      } catch (error) {
        setErrorMessage('Sorry, something went wrong...');

        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    };

    dataFetch();
  }, []);

  if (!schoolData.length) return <Loading />;
  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <CompareResult
      schoolData={schoolData}
    />
  );
}

export default CompareResultPage;
