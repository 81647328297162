import { api } from '../axios/config';
import { handleApiError } from '../util/errorHelper';

const baseUrl = '/api/users';

const fetchUserSubscription = async (userId, token) => {
  try {
    const response = await api.get(`${baseUrl}/${userId}/subscriptions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default {
  fetchUserSubscription,
};
