import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import columns from '../../util/rankingHelper/columns';
import Loading from '../../component/Loading';

function Ranking({ rankingData }) {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const navigate = useNavigate();

  const isRowSelectable = (params) => {
    if (rowSelectionModel.includes(params.id)) {
      return true;
    }
    if (rowSelectionModel.length >= 4) {
      return false;
    }
    return true;
  };

  const handleCompare = () => {
    if (rowSelectionModel.length < 2) {
      // eslint-disable-next-line no-alert
      alert('Please select more than 2 schools.');
      return;
    }

    navigate('/compare-result', { state: { rankingData: rowSelectionModel } });
  };

  return (
    <>
      {(rowSelectionModel.length >= 4) && (
      <Alert variant="outlined" severity="warning">
        Please select upto 4 schools.
      </Alert>
      )}
      <div>Ranking</div>
      <Button variant="outlined" onClick={handleCompare}>Compare Schools</Button>
      { (rankingData && rankingData.length) ? (
        <DataGrid
          rows={rankingData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          pageSizeOptions={[5, 50]}
          checkboxSelection
          getRowId={(row) => row.school_id}
          onRowSelectionModelChange={setRowSelectionModel}
          isRowSelectable={isRowSelectable}
          rowSelectionModel={rowSelectionModel}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      ) : (
        <Box sx={{ display: 'flex' }}>
          <Loading />
        </Box>
      )}
    </>
  );
}

export default Ranking;
