import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AdminContext } from '../../context';

function AdminHome() {
  const { currentProfileYear } = useContext(AdminContext);

  return (
    <>
      <h1>Admin Home</h1>
      <hr />
      <div>
        <Link to="/admin/profile-years">
          Profile Years
        </Link>
      </div>
      <hr />
      <div>
        <Link to={`/admin/profile-years/${currentProfileYear}/rank-products`}>
          Rank Page
        </Link>
      </div>
      <hr />
    </>
  );
}

export default AdminHome;
