import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Alert,
} from '@mui/material';

function Compare({ onSubmit, compareOptionData }) {
  const [selectedOptionData, setSelectedOptionData] = useState([]);
  const { control, handleSubmit } = useForm({
    reValidateMode: 'onBlur',
  });

  const onFormSubmit = () => {
    if (selectedOptionData.length < 2) {
      // eslint-disable-next-line no-alert
      alert('More than 1 school needed for comparison. Please try again.');
      return false;
    }
    onSubmit(selectedOptionData);
    return false;
  };

  // event param is required do not remove
  const handleChange = (event, value) => setSelectedOptionData(value);

  return (
    <div className="App">
      {(selectedOptionData.length >= 4) && (
      <Alert variant="outlined" severity="warning">
        Please select upto 4 schools.
      </Alert>
      )}
      { compareOptionData && compareOptionData.length ? (
        <Box component="form" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              { compareOptionData.length
            && (
            <Controller
              control={control}
              name="object-component"
              render={({
                field: {
                  ref, onChange, ...field
                },
              }) => (
                <Autocomplete
                  multiple
                  options={compareOptionData}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={() => (selectedOptionData.length > 3)}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      {...field}
                      {...params}
                      fullWidth
                      inputRef={ref}
                      variant="filled"
                      label="Select upto 4 schools"
                    />
                  )}
                />
              )}
            />
            )}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit">Submit</Button>
            </Grid>
          </Grid>
        </Box>
      ) : null }
    </div>
  );
}

export default Compare;

// https://codesandbox.io/p/sandbox/react-hook-form-mui-xkz3qg?file=%2Fsrc%2FApp.js%3A32%2C3-286%2C5
