export const USD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const GBP = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

export const CAN = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
});
