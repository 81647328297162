/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import Loading from '../../component/Loading';

function CompareResult({ schoolData }) {
  if (schoolData == null) return <Loading />;

  return (
    <>
      <h1>CompareResult</h1>
      { (schoolData && schoolData.length)
        ? (schoolData.map((school) => (
          <div>
            <Link to={`/schools/${kebabCase(school.display_name)}/${school.sp_uuid}`} state={school}>
              {school.display_name}
              {' '}
              -
              {school.profile_year}
            </Link>
          </div>
        )))
        : null}
    </>
  );
}

export default CompareResult;
