const handleApiError = (error) => {
  if (error.response) {
    // Handle API response errors
    throw new Error(`Error: ${error.response.data.message}`);
  } else if (error.request) {
    // Handle network errors
    throw new Error('Error: Network error');
  } else {
    // Handle unexpected errors
    throw new Error('Error: An unexpected error occurred');
  }
};

export default handleApiError;
