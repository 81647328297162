import React from 'react';
import Error from './Error';

function ErrorPage({ errorMessage }) {
  return (
    <Error errorMessage={errorMessage} />
  );
}

export default ErrorPage;
