import React from 'react';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { USD, GBP, CAN } from '../currency';

const columns = [
  {
    field: 'rank', headerName: 'Rank', width: 100, sortable: true,
  },
  {
    field: 'display_name',
    headerName: 'School',
    sortable: true,
    width: 200,
    renderCell: (params) => (
      <Link to={`/schools/${kebabCase(params.row.display_name)}/${params.row.sp_uuid}`} state={params.row}>
        {params.row.display_name}
      </Link>
    ),
  },
  {
    field: 'city', headerName: 'City', width: 150, sortable: true,
  },
  {
    field: 'state',
    headerName: 'State',
    width: 75,
  },
  {
    field: 'percent_ivy_mit_stanford',
    headerName: 'MIT Stanford Ivy League',
    width: 75,
    valueGetter: (params) => {
      if (!params.value) {
        return params.value;
      }
      return (Number(params.value)).toFixed(2) * 100;
    },
    valueFormatter: (params) => {
      if (!params.value) {
        return params.value;
      }
      return `${params.value.toLocaleString()} %`;
    },
  },
  {
    field: 'overall_rating',
    headerName: 'Rating (5)',
    sortable: true,
    width: 100,
    type: 'number',
    valueFormatter: (params) => {
      if (!params.value) {
        return 'N/A';
      }
      return (Number(params.value)).toFixed(2);
    },
  },
  {
    field: 'review_count',
    headerName: 'Reviews',
    sortable: true,
    width: 75,
    type: 'number',
    valueGetter: (params) => {
      if (!params.row.review_count) {
        return 0;
      }
      return Number(params.row.review_count);
    },
  },
  {
    field: 'day_tuition',
    headerName: 'Day Tuition',
    sortable: true,
    width: 150,
    type: 'number',
    sortComparator: (a, b) => {
      const a1 = (a === 'N/A') ? '0' : a.slice(1);
      const b1 = (b === 'N/A') ? '0' : b.slice(1);
      return ((Number(a1.replace(/,/g, ''))) - (Number(b1.replace(/,/g, ''))));
    },
    valueGetter: (params) => {
      if (!params.row.day_tuition) {
        return 'N/A';
      }
      const amount = Number(params.row.day_tuition);
      return (params.row.country === 'US')
        ? `${USD.format(amount)}`
        : (params.row.country === 'UK')
          ? `${GBP.format(amount)}`
          : `${CAN.format(amount)}`;
    },
  },
  {
    field: 'boarding_tuition',
    headerName: 'Boarding Tuition',
    sortable: true,
    width: 150,
    type: 'number',
    sortComparator: (a, b) => {
      const a1 = (a === 'N/A') ? '0' : a.slice(1);
      const b1 = (b === 'N/A') ? '0' : b.slice(1);
      return ((Number(a1.replace(/,/g, ''))) - (Number(b1.replace(/,/g, ''))));
    },
    valueGetter: (params) => {
      if (!params.row.boarding_tuition) {
        return 'N/A';
      }
      const amount = Number(params.row.boarding_tuition);
      return (params.row.country === 'US')
        ? `${USD.format(amount)}`
        : (params.row.country === 'UK')
          ? `${GBP.format(amount)}`
          : `${CAN.format(amount)}`;
    },
  },
  {
    field: 'country',
    headerName: 'Country',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 75,
  },
];

export default columns;
