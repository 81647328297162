import React from 'react';

function School({ schoolData }) {
  return (
    <div>
      <ul>
        { (schoolData != null && schoolData.length) ? (
          Object.keys(schoolData[0]).map((key) => ((!Array.isArray(schoolData[0][key]))
            ? (
              <li key={key}>
                {key}
                {' '}
                {' : '}
                {' '}
                {schoolData[0][key]}
              </li>
            )
            : (schoolData[0][key].map((arrKey) => Object.keys(arrKey).map((item) => (
              <li key={item}>
                {item}
                {' : '}
                {arrKey[item]}
              </li>
            ))))
          ))
        ) : null}
      </ul>
    </div>
  );
}

export default School;
