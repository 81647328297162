import { api } from '../axios/config';
import { handleApiError } from '../util/errorHelper';

const baseUrl = '/api/schools';

const fetchSchool = async (schoolId, profileYear, token) => {
  try {
    const response = await api.get(`${baseUrl}/${schoolId}/profile-years/${profileYear}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const fetchSchools = async (schoolIds, profileYear, token) => {
  try {
    const ids = schoolIds.toString();
    const response = await api.get(`${baseUrl}/profile-years/${profileYear}?school_id=${ids}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const fetchBasicSchoolInfo = async (profileYear, token) => {
  try {
    const response = await api.get(`${baseUrl}/profile-years/${profileYear}/basic`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default {
  fetchSchool,
  fetchBasicSchoolInfo,
  fetchSchools,
};
