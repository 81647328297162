import React from 'react';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';

function SearchResult({ searchResult }) {
  return (
    <>
      <h1>Search Result</h1>
      <ul>
        { (searchResult && searchResult != null)
          ? searchResult.map((item) => (
            <li key={item.school_id}>
              <Link to={`/schools/${kebabCase(item.display_name)}/${item.sp_uuid}`} state={item}>
                {item.display_name}
                {' '}
                -
                {' '}
                {item.profile_year}
              </Link>
            </li>
          ))
          : null }
      </ul>
    </>
  );
}

export default SearchResult;
