import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { UserContext, AdminContext } from '../../context';
import { adminService } from '../../service';
import ErrorPage from '../error/ErrorPage';

function ProfileYearDropDown() {
  const location = useLocation();
  const { token } = useContext(UserContext);
  const { register, handleSubmit } = useForm();
  const {
    setCurrentProfileYear,
    setAvailableProfileYears,
    currentProfileYear,
    availableProfileYears,
  } = useContext(AdminContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const isAdminPage = location.pathname === '/admin';

  const onSubmit = (data) => {
    setCurrentProfileYear(data.year);
  };

  useEffect(() => {
    const fetchProfileYears = async () => {
      try {
        const profileYears = await adminService.fetchProfileYears(token);
        const availableYears = profileYears.map((item) => item.profile_year);
        setAvailableProfileYears(availableYears);
        setCurrentProfileYear(availableYears[0]);
      } catch (error) {
        setErrorMessage(error.message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
      }
    };

    fetchProfileYears();
  }, []);

  if (errorMessage) return <ErrorPage errorMessage={errorMessage} />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {availableProfileYears
      && (
      <select {...register('year')} disabled={!isAdminPage}>
        <option value="">Select a year</option>
        {availableProfileYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      )}
      { isAdminPage && (
        <>
          <button type="submit">Submit</button>
          <p>
            Selected Year:
            {currentProfileYear}
          </p>
        </>
      )}
    </form>
  );
}

export default ProfileYearDropDown;
