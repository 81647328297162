import { api } from '../axios/config';
import { handleApiError } from '../util/errorHelper';

const baseUrl = '/api/orders';

const addOrder = async (user_id, product_id, duration) => {
  try {
    const response = await api.post(`${baseUrl}`, {
      user_id,
      product_id,
      duration,
    });

    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const fetchSubscriptionPlans = async () => {
  try {
    const response = await api.get(`${baseUrl}/subscription-plans`);
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

/* stripe */
const fetchStripeConfig = async () => {
  try {
    const response = await api.get(`${baseUrl}/stripe-config`);
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const createPaymentIntent = async (payment) => {
  try {
    const { amount, currency } = payment;

    const response = await api.post(`${baseUrl}/stripe-create-payment-intent`, {
      amount,
      currency,
    });

    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default {
  addOrder,
  fetchSubscriptionPlans,
  fetchStripeConfig,
  createPaymentIntent,
};
