import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useLocation } from 'react-router-dom';
import {
  TextField, Autocomplete, IconButton, Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { schoolService, adminService, productService } from '../../../service';
import { UserContext, AdminContext } from '../../../context';
import ErrorPage from '../../error/ErrorPage';

function RankForm() {
  const location = useLocation();
  const { rank_product_id: rankProductId } = useParams();
  const { token } = useContext(UserContext);
  const { currentProfileYear } = useContext(AdminContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rankProduct, setRankProduct] = useState({});
  const [rankedSchools, setRankedSchools] = useState({});
  const [schools, setSchools] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const isUpdate = location.pathname.includes('/update');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    unregister,
  } = useForm({
    defaultValues: isUpdate
      ? []
      : Array.from({ length: rankProduct.rank_max || 5 }, () => ({
        rank: '',
        school: null,
        graduateCount: '',
      })),
  });

  const [rows, setRows] = useState(
    isUpdate ? [] : Array(5).fill({ rank: '', school: null, graduateCount: '' }),
  );

  const handleError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  };

  const fetchRankedSchools = async () => {
    try {
      const response = await productService.fetchRankProduct(
        rankProductId,
        currentProfileYear,
        token,
      );
      setRankedSchools(
        response.map((school) => ({
          school_id: school.school_id,
          rank: school.school_rank,
          display_name: school.display_name,
        })),
      );
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleAddRow = () => {
    setRows([...rows, { rank: '', school: null, graduateCount: '' }]);
  };

  const handleRemoveRow = (index) => {
    unregister(`rank[${index}]`);
    unregister(`school[${index}]`);
    unregister(`graduateCount[${index}]`);
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleSchoolChange = (index, newSchool) => {
    setValue(`school[${index}]`, newSchool);
    const selectedSchoolId = newSchool ? newSchool.school_id : null;
    setSelectedSchools(
      [...selectedSchools.slice(0, index), selectedSchoolId, ...selectedSchools.slice(index + 1)],
    );
  };

  const transposeArray = (arr) => arr[0]
    .map((_, columnIndex) => arr.map((row) => row[columnIndex]))
    .filter((subArr) => !subArr.includes(NaN) && !subArr.includes('') && !subArr.includes(null));

  const onSubmit = async (data) => {
    const { rank, school } = data;
    const schoolRank = rank.map((item) => Number(item));
    const schoolIds = school.map((item) => Number(item.school_id));
    const rankProductIds = school.map(() => Number(rankProductId));
    const rankSchoolPayload = transposeArray([rankProductIds, schoolIds, schoolRank]);

    try {
      await adminService.addRankSchool(rankSchoolPayload, token);
    } catch (error) {
      handleError(error.message);
    }

    const result = await adminService.addRankSchool(rankSchoolPayload, token);
    return result;
  };

  const filteredSchools = useMemo(
    () => schools.filter((school) => !selectedSchools.includes(school.school_id)),
    [schools, selectedSchools, rankedSchools],
  );

  useEffect(() => {
    if (isUpdate) {
      fetchRankedSchools();
    }
  }, [isUpdate]);

  useEffect(() => {
    if (rankedSchools.length) {
      setValue(
        'rank',
        rankedSchools.map((school) => school.rank),
      );
      setValue(
        'school',
        rankedSchools.map((school) => {
          const { school_id, display_name } = school;
          return { school_id, display_name };
        }),
      );
      setRows(Array.from({ length: rankProduct.rank_max }, (_, index) => index));
    }
  }, [rankedSchools, rankProduct.rank_max], setValue);

  useEffect(() => {
    setRows(Array.from({ length: rankProduct.rank_max }, (_, index) => index));
  }, [rankProduct.rank_max]);

  useEffect(() => {
    const fetchRankCategory = async () => {
      try {
        const data = await productService.fetchBasicRankProductInfo(currentProfileYear);
        const product = data.find((item) => item.rank_product_id === Number(rankProductId));
        setRankProduct(product);
      } catch (error) {
        handleError(error.message);
      }
    };

    fetchRankCategory();
  }, [token, currentProfileYear]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const result = await schoolService.fetchBasicSchoolInfo(currentProfileYear, token);

        if (Object.keys(rankProduct).length > 0) {
          const filtered = result.filter((school) => school.country === rankProduct.country);
          setSchools(filtered);
        }
      } catch (error) {
        handleError(error.message);
      }
    };

    fetchSchools();
  }, [currentProfileYear, token, rankProduct]);

  if (!schools.length || !filteredSchools.length) return null;

  return (
    <>
      <h1>
        {Object.keys(rankProduct).length > 0 ? rankProduct.display_name : null}
        {' '}
        (rank_product_id:
        {' '}
        {rankProductId}
        )
      </h1>
      {errorMessage && <ErrorPage errorMessage={errorMessage} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>School Rank</th>
              <th>School</th>
              <th>Graduate Count</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <TextField
                    {...register(`rank[${index}]`)}
                    error={!!errors.rank && errors.rank[index]}
                    helperText={errors.rank?.[index]?.message}
                    defaultValue={index + 1}
                  />
                </td>
                <td style={{ width: '400px' }}>
                  <Autocomplete
                    {...register(`school[${index}]`)}
                    options={filteredSchools}
                    getOptionLabel={(option) => `${option.display_name}`}
                    onChange={(event, newSchool) => handleSchoolChange(index, newSchool)}
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option) => (
                      <li {...props} key={option.uuid}>
                        {`${option.display_name}, ${option.city} - ${option.profile_year}`}
                      </li>
                    )}
                    isOptionEqualToValue={() => true}
                    defaultValue={rankedSchools[index]}
                  />
                </td>
                <td>
                  <TextField
                    {...register(`graduateCount[${index}]`)}
                    type="number"
                    error={!!errors.graduateCount && errors.graduateCount[index]}
                    helperText={errors.graduateCount?.[index]?.message}
                  />
                </td>
                <td>
                  <IconButton onClick={() => handleRemoveRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button type="submit" variant="contained">
          Submit
        </Button>
        <Button variant="contained" onClick={handleAddRow}>
          Add Row
        </Button>
      </form>
    </>
  );
}

export default RankForm;
