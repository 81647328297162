import { api } from '../axios/config';
import { handleApiError } from '../util/errorHelper';

const baseUrl = '/api/admin';

const fetchProfileYears = async (token) => {
  try {
    const response = await api.get(`${baseUrl}/school-profile/profile-years`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const fetchRankCategory = async (token) => {
  try {
    const response = await api.get(`${baseUrl}/rank-category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const copySchoolProfiles = async (payload, token) => {
  try {
    const {
      sourceProfileYear: source_profile_year,
      targetProfileYear: target_profile_year,
    } = payload;

    const response = await api.post(
      `${baseUrl}/school-profile/copy`,
      {
        source_profile_year,
        target_profile_year,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const addRankProduct = async (payload, token) => {
  try {
    const {
      rankProductId: rank_product_id,
      rankCategoryId: rank_category_id,
      name,
      description,
      currentProfileYear: profile_year,
      active,
    } = payload;

    const response = await api.post(
      `${baseUrl}/rank-product`,
      {
        rank_product_id,
        rank_category_id,
        name,
        description,
        profile_year,
        active,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

const addRankSchool = async (payload, token) => {
  try {
    const response = await api.post(
      `${baseUrl}/rank-school`,
      { payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const { data } = response;
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default {
  fetchProfileYears,
  copySchoolProfiles,
  fetchRankCategory,
  addRankProduct,
  addRankSchool,
};
