import React from 'react';

function AdminFooter() {
  return (
    <>
      <h3>Admin Footer</h3>
      <div>Footer... </div>
    </>
  );
}

export default AdminFooter;
